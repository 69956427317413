<template>
  <main>
    <!-- component -->
    <section class="article-section">
      <div class="w-full p-8 flex justify-around">
        <a-row :gutter="10">
          <a-col :span="4">
            <div class="rounded bg-grey-light w-64 p-2 card-bg">
              <div class="flex justify-between py-1">
                <h3 class="text-lg">New Pitch</h3>
              </div>
              <div class="text-sm mt-2">
                <draggable
                  class="draggable-list AAA"
                  :list="articles.newPitch"
                  group="articles"
                  @start="startDrag"
                  @end="endDrag"
                  @add="add"
                  @remove="remove"
                  @update="update"
                  @choose="choose"
                  @unchoose="unchoose"
                  @sort="sort"
                  @filter="filter"
                  @clone="clone"
                >
                  <div
                    v-for="(article, i) in articles.newPitch"
                    :key="i"
                    style="background: #ddd"
                  >
                    <div
                      class="bg-white p-2 py-6 rounded mt-1 border-b border-grey cursor-pointer hover:bg-grey-lighter"
                    >
                      <p>{{ article }}</p>
                      <div
                        class="text-grey-darker mt-2 ml-2 flex justify-between items-start"
                      >
                        <span class="text-xs flex items-center"> 3/5 </span>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </a-col>

          <a-col :span="4">
            <div class="rounded bg-grey-light w-64 p-2 card-bg">
              <div class="flex justify-between py-1">
                <h3 class="text-lg">Content in Progress</h3>
              </div>
              <div class="text-sm mt-2">
                <div class="">
                  <draggable
                    class="draggable-list BBB"
                    :list="articles.contentInProgress"
                    group="articles"
                    @start="startDrag"
                    @end="endDrag"
                    @add="add"
                    @remove="remove"
                    @update="update"
                    @choose="choose"
                    @unchoose="unchoose"
                    @sort="sort"
                    @filter="filter"
                    @clone="clone"
                  >
                    <div
                      v-for="(article, i) in articles.contentInProgress"
                      :key="i"
                      style="background: #ddd"
                    >
                      <div
                        class="bg-white p-2 py-6 rounded mt-1 border-b border-grey cursor-pointer hover:bg-grey-lighter"
                      >
                        <p>{{ article }}</p>
                        <div
                          class="text-grey-darker mt-2 ml-2 flex justify-between items-start"
                        >
                          <span class="text-xs flex items-center"> 3/5 </span>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </section>
  </main>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "kanban",
  components: {
    draggable,
  },
  data() {
    return {
      articles: {
        newPitch: [
          "Introduction to Tailwind CSS",
          "GrapQL and Vue: Consume API",
          "Frontend:React vs Vue",
        ],
        contentInProgress: ["Vuex vs Pinia", "Svelte vs React"],
        draftReceived: ["Getting started with Next.JS", "What is vite.js"],
        inReview: [],
      },
    };
  },
  methods: {
    endDrag(aa) {
      console.log("endDrag", aa);
    },
    startDrag(aa) {
      console.log("startDrag", aa);
    },
    add(aa) {
      console.log("add", aa);
    },
    remove(aa) {
      console.log("remove", aa);
    },
    update(aa) {
      console.log("update", aa);
    },

    choose(aa) {
      console.log("choose", aa);
    },

    unchoose(aa) {
      console.log("unchoose", aa);
    },

    sort(aa) {
      console.log("sort", aa);
    },

    filter(aa) {
      console.log("filter", aa);
    },

    clone(aa) {
      console.log("clone", aa);
    },
  },
};
</script>
